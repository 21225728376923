/* .navigation {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0.5rem 0rem;
    background-color: transparent;
    color: black;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  } */
  .navigation {
    position: fixed;
    /* top: -20;
    left: 2; */
    width: 100%;
    height: 80px;
    padding: 0.5rem 0rem;
    display: flex;
    background-color: transparent;
    color: black;
    text-align: center;
    /* box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23); */
    z-index: 11;
    /* clip-path: polygon(100%, 0%); */
    
  }
  .navigation.scrolled {
    background-color: #3498db;
     font-weight: 500;
     text-align: center;
  }
  .logo {
    /* margin: 22px; */
    margin-left: 32px;
  }
  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
    margin: 22px;
  }
  
  .navigation-menu {
    margin-left: auto;
  }
  
  .navigation-menu ul {
    display: flex;
    padding: 0;
    margin-top: 12px;
    margin: 22px;
  }
  
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    color: white;
  }
  
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  
  .hamburger:hover {
    color: #4b62bd;
  }
  
  @media screen and (max-width: 550px) {
    .hamburger {
      display: block;
    }
  
    .navigation-menu ul {
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      height: calc(100vh - 40px);
      background-color: #3498db;
      border-top: 1px solid black;
      display: none;
    }
  
    .navigation-menu li {
      text-align: center;
      margin: 0;
      color: rgb(196, 84, 38);
    }
  
    .navigation-menu li a {
      color: rgb(196, 84, 38);
      width: 100%;
      padding: 1.5rem 0;
      font-weight: 900;
    }
  
    .navigation-menu li:hover {
      background-color: #eee;
    }
  
    .navigation-menu.expanded ul {
      display: block;
    }
  }
  