.block {
  background-color: rgb(219, 251, 251);
}
.services {
    color: rgb(196, 84, 38);
    text-align: center;
    font-size: 44px;
    font-weight: 900;
    margin-top: 28px;
 
}
.containe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px;
    color: black;
}
.content {
  display: block;
  justify-content: space-between;
  text-align: center;
  margin: 12px;
  color: rgb(30, 30, 30);
}

.Titre {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(196, 84, 38);
  font-size: 24px;
  font-weight: 700;
  margin: 12px;
}
.row {
    flex-direction: row;
    justify-content: space-around;
    background-color: transparent;
    width: 100%;
    height: auto;
    padding: 10px;
    text-align: center;

}
.styleImage {
       width: 100%;
       height: 80%;

}

.numerique {
    font-size: 33px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: rgb(196, 84, 38);
}
.tesIlink {
    margin: 22px;
    font-size: 33px;
    font-weight: bold;
    color: rgb(196, 84, 38);
}
.rowContent {
    margin: 8px;
    flex: 1;
    font-size: 16px;
}
.desc {
  color: #302f2f;
}

@media (max-width: 768px) {
    .services {
      font-size: 32px;
    }
    .containe {
      flex-direction: column;
    }
    .content {
      flex-direction: column;     
      margin: 16px;
    }
    .Titre {
      font-size: 20px;
    }
    .row {
        flex-direction: column;
      justify-content: center;
    }
    .rowContent {
        margin: 14px;
        display: block;
        justify-content: center;
        align-items: center;
      }
      .rowConten img {
        width: 100%;
        height: auto;
      }
      .rowContent {
        margin-top: 20px;
      }
    .numerique {
      font-size: 26px;
    }
    .tesIlink {
      font-size: 26px;
    }
    .rowContent {
      margin: 14px;
      flex: 1;
    }
  }

  /*  pour les grand ecrans */
  @media (min-width: 768px) {
    .container {
      width: 100%;
    }
    .row {
      margin: 2px;
      /* flex-direction: column; */
    }
    .rowContent {
      width: 100%;
    }
    .styleImage {
      width: 100%;
      height: auto;
    }
    .rowConten {
      width: 50%;
    }
  }
