.row {
    flex-direction: row;
    justify-content: space-around;
    background-color: transparent;
    width: 100%;
    height: auto;
    padding: 10px;
    text-align: center;

}
.styleImage {
       width: 100%;
       height: 80%;

}
.apropo {
    display: flex;
    justify-content: flex-start;
    color: rgb(196, 84, 38);
    font-size: 20px;

}

.rowContent {
    margin: 0px;
    flex: 1;
    font-size: 16px;
}

@media (max-width: 768px) {
    .services {
      font-size: 32px;
    }
    .row {
        flex-direction: column;
      justify-content: center;
    }
    .rowContent {
        display: block;
        justify-content: center;
        align-items: center;
      }
      .rowConten img {
        width: 100%;
        height: auto;
      }
      .rowContent {
        margin-top: 20px;
      }
    .numerique {
      font-size: 26px;
    }
    .tesIlink {
      font-size: 26px;
    }
    .rowContent {
      margin: 14px;
      flex: 1;
    }
  }

  /*  pour les grand ecrans */
  @media (min-width: 768px) {
    .container {
      width: 100%;
    }
    .row {
      margin: 2px;
      /* flex-direction: column; */
    }
    .rowContent {
      width: 100%;
    }
    .styleImage {
      width: 100%;
      height: auto;
    }
    .rowConten {
      width: 50%;
    }
  }

  .blocIcon {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #0D2E44;
    justify-content: center;
    align-items: center;

    
  }
  .icons {
    width: 30px;
    height: 30px;
  color: white;
  }

  .textContent {
    text-align: start;
    color: #6d6d6d;
  }