
.rowContent {
    margin: 8px;
    flex: 1;
    font-size: 16px;
}

.contact  {
 color: #413f3f;

}
.no-border {
    border: none; 
    width: 100%; 
  }



  .input-wrapper {
    position: relative;
    margin-bottom: 20px;
  }
  
  .input-wrapper input,
  .input-wrapper textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc; /* Ajoute une bordure inférieure */
    padding: 5px 0; /* Espace sous la bordure */
  }
  
  /* Style facultatif pour la bordure inférieure au survol */
  .input-wrapper input:hover,
  .input-wrapper textarea:hover {
    border-bottom: 2px solid #007bff; /* Bordure inférieure plus épaisse au survol */
  }
  a {
    text-decoration: none; /* Supprime le soulignement */
    color: inherit; /* Utilisez la couleur héritée (par défaut) */
  }
  
  /* Styles personnalisés pour les liens */
  a:hover {
    text-decoration: none; /* Soulignez le lien au survol si nécessaire */
    color: #007bff; /* Changez la couleur du texte au survol si nécessaire */
  }
  
  


@media (max-width: 768px) {
    .services {
      font-size: 32px;
    }
    .row {
        flex-direction: column;
      justify-content: center;
    }
    .rowContent {
        margin: 14px;
        display: block;
        justify-content: center;
        align-items: center;
      }
      .rowConten img {
        width: 100%;
        height: auto;
      }
      .rowContent {
        margin-top: 20px;
      }
    .numerique {
      font-size: 19px;
    }
    .tesIlink {
      font-size: 26px;
    }
    .rowContent {
      margin: 14px;
      flex: 1;
    }
  }

  .submit-button {
    justify-content: center;
    display: inline-block;
    padding: 5px 35px;
    color: #ffffff;
    border-radius: 0;
    -webkit-transition: all 0.3s;
    background-color: #007bff;
    transition: all 0.3s;
    border: none;
    font-size: 23px;
    border-radius: 32px;
  }