.section {
    background-color: rgb(217, 191, 156);
    margin-top: -22px;
}
.section-heading {
    display: flex;
    justify-content: center;
    color: rgb(196, 84, 38);
    text-align: center;
    margin: 22px;
    font-size: 44px;
    font-weight: 900;
}
.row {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 768px) {
    .w-container h1 {
        display: flex;
        justify-content: center;
        color: rgb(196, 84, 38);
        text-align: center;
        margin: 22px;
        font-size: 30px;
        font-weight: 900;
    }
    .row {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .heading h2{
        color: #3498db;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 30px;
        font-weight: 900; 
    }
}
.heading {
    color: #3498db;
    justify-content: center;
    text-align: center;
    font-size: 30px;
    font-weight: 900; 
}
.centered {
    text-align: center;
 
}
.simulateur {
    color: rgb(196, 84, 38);
    text-align: center;
    font-size: 44px;
    font-weight: 900;
  }