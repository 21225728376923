@media (max-width: 1300px) {}

@media (max-width: 1120px) {}

@media (max-width: 992px) {
  .hero_area {
    min-height: auto;
  }

  .hero_area {
    background: linear-gradient(130deg, #231a6f, #0f054c);
  }

  .hero_area .hero_bg_box {
    display: none;
  }

  .slider_section {
    padding: 45px 0 75px 0;
  }

  .custom_nav-container .navbar-nav {
    padding-top: 15px;
    align-items: center;
  }


  .custom_nav-container .navbar-nav .nav-item .nav-link {
    padding: 5px 25px;
    margin: 5px 0;
  }


}

@media (max-width: 768px) {
  .about_section .img-box {
    margin-bottom: 45px;
  }

}

@media (max-width: 576px) {
  .header_section .header_top .contact_nav a span {
    display: none;
  }


  .client_section .client_container {
    width: 100%;
  }

  .client_section .box {
    margin: 0;
  }

  .owl-nav {
    display: flex;
    justify-content: center;
    margin-top: 45px;
  }

  .client_section .owl-carousel .owl-nav .owl-prev,
  .client_section .owl-carousel .owl-nav .owl-next {
    position: unset;
    margin: 0 2.5px;
  }
}

@media (max-width: 480px) {
  .slider_section .detail-box h1 {
    font-size: 2rem;
  }

  .track_section form {
    flex-direction: column;
    align-items: flex-start;
  }

  .track_section form input {
    width: 100%;
  }

  .track_section form button {
    padding: 10px 60px;
    margin-top: 10px;
  }
}

@media (max-width: 420px) {
  .service_section .box {
    flex-direction: column;
  }

  .service_section .box .img-box {
    justify-content: flex-start;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 768px) {
  #index {
    width: 100%;
  }
}
@media (max-width: 376px) {
  
}
@media (max-width: 768px) {
  .img-box img{
    display: none;
  }
  .slider_section .detail-box h1 {
    font-size: 14px;
    font-weight: bold;
    /* text-transform: uppercase; */
    color: hsl(0, 74%, 46%);
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}


.slider_section {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: 45px 0 145px 0;
}

.slider_section .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.slider_section #customCarousel1 {
  width: 100%;
  position: unset;
}

.slider_section .detail-box {
  color: #00204a;
}

.slider_section .detail-box h1 {
  font-size: 3rem;
  font-weight: bold;
  /* text-transform: uppercase; */
  margin-bottom: 25px;
  color: #ffffff;
}

.slider_section .detail-box p {
  color: #fefefe;
  font-size: 17px;
}

.slider_section .img-box {
  margin: 45px 0;
}

 .img-box img {
  width: 100%;
  -webkit-animation: upDown 5s infinite;
          animation: upDown 5s infinite;
}

@-webkit-keyframes upDown {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
  }
  50% {
    -webkit-transform: translateY(45px);
            transform: translateY(45px);
  }
  100% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
  }
}

@keyframes upDown {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
  }
  50% {
    -webkit-transform: translateY(45px);
            transform: translateY(45px);
  }
  100% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
  }
}

.slider_section .carousel-indicators {
  position: unset;
  margin: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.slider_section .carousel-indicators li {
  background-color: #ffffff;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  opacity: 1;
}

.slider_section .carousel-indicators li.active {
  width: 20px;
  height: 20px;
  background-color: #00bbf0;
}

.service_section {
  position: relative;
}