.section-heading {
  color: "#f89b29";
  text-align: center;
  font-size: 44px;
  font-weight: 900;
}
.w-container {

}
@media only screen and (max-width: 768px) {

  .w-container h2{
    color: "#f89b29";
    text-align: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 900;
  }
  .img-boxe img{
    align-items: center;
    justify-content: center;
    margin: 22px;
  }

 
    .why_section .box {
      padding: 12px;
      justify-content: center;
      align-items: center;
    }
}

.why_section .box {
    margin-top: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
  }
  
  /* .why_section .box .img-boxe {
    margin-bottom: 20px;
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
    border-radius: 100%;
    border: 5px solid #00204a;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  } */
  
  /* .why_section .box .img-boxe img {
    width: 55px;
    height: auto;
    fill: #00204a;
  } */
  
  .why_section .box h5 {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .why_section .box p {
    margin-bottom: 0;
  }
  
 .btn-box{
    padding: 10px 45px;
    background-color: #00bbf0;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 33px;
    margin-left: 62px;
    margin-right: 62px;

  }
.btn-box:hover {
    background-color: #007fa4;
    color: #ffffff;
    text-decoration: none;
    list-style: none;
  }
  .why_section .box {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

    .why_section .box .img-boxe {
      margin-bottom: 20px;
      width: 120px;
      height: 120px;
      min-width: 120px;
      min-height: 120px;
      border-radius: 100%;
      border: 5px solid;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
      .why_section .img-boxe img {
        width: 105px;
        height: auto;
        border-radius: 50px;
      }
    

      .why_section h5 {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .why_section p {
      margin-bottom: 0;
    }
  


 
