.plateforme {
  color: rgb(196, 84, 38);
  text-align: center;
  font-size: 44px;
  font-weight: 900;

}
 .paragraphe {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

.alice-carousel ul li img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
