.carousel {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
  align-items: center;

  text-align: center;
  justify-content: center;
  }

  .image {
    position: absolute;
    width:  100%;
    height: 100%;
    overflow: hidden;
  }
  /* .headerText {
    position: absolute;
    color: #fff;
    text-align: end;
  } */
  .headerImg {
    position: absolute;
    top: 5%;
    left: 60%;
    color: #fff;
    display: flex;
    justify-content: center;
    text-align: center;
  }
.textNumerique {
  color: rgb(42, 178, 218);
}
.lien {
 font-size: 32px;
}
.gauche {
  position: absolute;
}
  .imgText img  {
    /* width: 100%; */
    -webkit-animation: upDown 5s infinite;
            animation: upDown 5s infinite;
  }
  .imgText h1  {
    /* width: 100%; */
    -webkit-animation: upDown 5s infinite;
            animation: upDown 5s infinite;
  }
  
  .slide {
     position: absolute;
   /* width: 60%;
    max-height: 100%;
    display: flex; */
    flex-direction: row;
    /* justify-content: center;
    align-items: center; */
  }
  
  .slide img {
    width: 60%;
    height: auto;
  }
  
  .slide p {
    font-size: 16px;
    color: #fff;
    left: 20%;
  }
  
  @media (max-width: 768px) {
    .slide {
    position: absolute; 
 }
 .headerTitle h2 {
  font-size: 40px;
  position: relative;
  top: 38px
 }
 .headerTitle h3 {
  font-size: 16px;
  text-align: center;
  top: 140px;
  position: absolute;
  padding: 0 124px;
 }
 .headerTitle h1 {
  font-size: 42px;
    line-height: 108px;
    font-weight: 700;
    color: rgb(196, 84, 38);
    letter-spacing: 0px;
    font-family: Montserrat;
 }
    .gauche p {
      align-items: center;
      font-size: 22px;
    }
    .gauche h1 {
      align-items: center;
      font-size: 22px;
    }
    .headerImg {
      position: absolute;
      top: 2%;
      left: 10%;
      color: #fff;
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .imgText img {
      width: 60%;
      animation: upDown 4s infinite;
      margin-left: 182px;
  }
    .image {
      position: absolute;
      width:  100%;
      height: 100%;
      overflow: hidden;
    }
    .carousel {
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      flex-direction: column;
    align-items: center;
  
    text-align: center;
    justify-content: center;
    }
    .headerText {
      position: absolute;
      font-size: 12px;
      top: 100px;
      text-align: start;
      color: #fff;
    }
  
  }
  .imgText {
    display: flex;
  }


  .headerText {
    position: relative;
    font-size: 1.2rem;
    top: 100px;
    text-align: start;
    color: #fff;
    

    z-index: 9;
    min-width: 647px;
    max-width: 947px;
    white-space: normal;
  }



  
  /* .image {
    max-width: 100%;
    max-height: 100%;
  } */
  
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  .headerTitle {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .entreprise {
    white-space: nowrap;
    font-size: 65px;
    line-height: 65px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    letter-spacing: 0px;
    font-family: Montserrat;
    text-shadow: rgb(0, 0, 0) 1px 1px;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    transform-origin: 50% 50% 0px;
    transform: matrix3d(1, 0, 0, 0, 0, 1)
  }
  
  .fintech {
  font-size: 148px;
    line-height: 108px;
    font-weight: 700;
    color: rgb(196, 84, 38);
    letter-spacing: 0px;
    font-family: Montserrat;
  }
 
  .textNumerique {
    color: rgb(42, 178, 218);
    font-size: 30px;
    transition: transform 0.2s ease; /* Animation de transformation */
    color: rgb(42, 178, 218);
  }
  
  .textNumerique:hover {
    transform: scale(1.1); /* Augmentez la taille du texte lors du survol */
  }
  
 
  
  .headerDesc {
    position: relative;
    text-align: left;
    margin: 0 auto;
    max-width: 80%;
  }
  
  
  .imgText {
    display: flex;
    justify-content: center;

  }
  
  .gauche {
    text-align: start;

  }
  
  .lien {
    color: #fff;
    font-size: 64px;
    font-weight: 800;
    letter-spacing: 0px;
    font-family: Montserrat;
  }
  
  .textNumerique {
    color: rgb(42, 178, 218);
    font-size:130px;
    font-weight: 800;
    letter-spacing: 0px;
    font-family: Montserrat;
  }
  
  