.service_section {
    position: relative;
  }
  .content {
    color: #e0e0e0;
  }
  .title {
    font-size: 33px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: #f89b29;
  }
  .service_section .box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    margin-top: 45px;
    background-color: #f8f8f9;
    padding: 20px;
    border-radius: 5px;
  }
  
  .service_section .box .img-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 125px;
    min-width: 75px;
    height: 75px;
    margin-bottom: 15px;
  }
  
  .service_section .box .img-box img {
    max-width: 100%;
    max-height: 100%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .service_section .box .detail-box h5 {
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .service_section .box .detail-box {
    color: #3498db;
    font-weight: 600;
  }
  
  .service_section .box .detail-box:hover {
    color: #3498db;
  }
  .team_section .heading_container {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  @media (max-width: 768px) {
    .about_section h1{
      color: rgb(196, 84, 38);
      font-size: 30px;
     } 
     .about_section p{
      color: rgb(255, 255, 255);
      font-size: 18px;
     } 

 
     .row-card{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
   
     .card-container {
      width: 370px;
      height: 380px;
      position: absolute;
      border-radius: 10px;
      box-shadow: 1 10px 20px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      padding-top: 22px;

    }

  }


.about_section {
 padding: 22px;
}  
.heading_container {
    margin-bottom: 45px;
    justify-content: center;
  }
  .client_section .heading_container {
      margin-bottom: 30px;
    }
.contentText {
  font-size: 24px;
  font-weight: 400;
}
 
    .btn {
        justify-content: center;
        display: inline-block;
        padding: 10px;
        color: #ffffff;
        border-radius: 0;
        -webkit-transition: all 0.3s;
        background-color: #da740d;
        transition: all 0.3s;
        border: none;
        font-size: 21px;
        border-radius: 32px;
        font-weight: bold;
      }

    .btn-box:hover {
        background-color: #3498db;
        text-decoration: none;
        color: #ffffff;
      }

      .row-card {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      
      .card-container {
        width: 280px; 
        margin-bottom: 20px;
        height: 280px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          box-shadow: 1 10px 20px rgba(0, 0, 0, 0.2);
          overflow: hidden;
          padding-top: 22px;
      }
      /* .row-card{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
      }

        .card-container {
          width: 270px;
          height: 280px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          box-shadow: 1 10px 20px rgba(0, 0, 0, 0.2);
          overflow: hidden;
          padding-top: 22px;
  
        } */
        
        .card {
          width: 100%;
          height: 100%;
          border-radius: inherit;
        }
        
        .card .front-content {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
        }
        
        .card .front-content p {
          font-size: 32px;
          font-weight: 700;
          opacity: 1;
          background: linear-gradient(-45deg, #f89b29 0%, #f89b29 100% );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
        }
     
        .card .content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          gap: 10px;
          background: linear-gradient(-45deg, #f89b29 0%, #ff0f7b 100% );
          color: #e8e8e8;
          padding: 20px;
          line-height: 1.5;
          border-radius: 5px;
          pointer-events: none;
          transform: translateY(96%);
          transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
        }
        
        .card .content .heading {
          font-size: 32px;
          font-weight: 700;
          color: white;
        }
        
        .card:hover .content {
          transform: translateY(0);
        }
        
        .card:hover .front-content {
          transform: translateY(-30%);
        }
        
        .card:hover .front-content p {
          opacity: 0;
        }
        
        

        