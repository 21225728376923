/* App.css */
.convertire {
  width: 100%;
  background-color: rgb(178, 204, 214);
}
.container {
  display: flex;
  justify-content: center;
}
/* Styles par défaut */
.simulateur {
  color: rgb(196, 84, 38);
  text-align: center;
  font-size: 44px;
  font-weight: 900;
}
@media only screen and (max-width: 768px) {
  .col-md-6 {
    width: 80%;
  }
  .envoie1, .envoie2, .envoie3, .envoie4 {
    margin-bottom: 10px; /* Ajoute un espacement entre les éléments */
  }
  .container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
  
  .simulateur span{
    color: rgb(196, 84, 38);
    text-align: center;
    font-size: 30px;
    font-weight: 900;
  }
}


.app-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  margin: 22px;
}

.left-section {
  flex: 1;
  min-width: 300px; /* Largeur minimale de la section gauche */
}

.country-list {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Deux colonnes réactives */
}

.country-list li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.flag {
  width: 30px;
  height: 20px;
  margin-right: 10px;
}

.right-section {
  flex: 1;
  min-width: 200px; /* Largeur minimale de la section droite */
}

.card {
  background: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdowns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

select {
  width: calc(50% - 10px); /* Deux dropdowns alignés horizontalement avec un espace de 10px */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

inputs {
  display: flex;
  flex-direction: column;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input:last-child {
  margin-bottom: 0;
}

.copy-right-text {
  font-size: 12px;
  color: #777;
  text-align: center;
}

/* Styles pour les écrans plus petits (par exemple, mobile) */
@media (max-width: 768px) {
  .app-container {
    flex-direction: column; /* Une colonne pour les écrans plus petits */
  }

  .left-section, .right-section {
    flex: none; /* Les sections n'ont plus de flexibilité pour s'adapter à la largeur de l'écran */
    min-width: auto; /* Pas de largeur minimale pour les sections */
  }

  .country-list li {
    margin-bottom: 5px; /* Réduire l'espacement entre les éléments de la liste */
  }

  .flag {
    width: 20px;
    height: 14px;
    margin-right: 5px; /* Réduire la taille des drapeaux */
  }

  .right-section {
    .dropdowns {
      flex-direction: row; /* Les dropdowns passent en colonne */
    }

    select {
      width: 100%; /* Les dropdowns occupent toute la largeur */
    }
  }
}

.container {
  display: flex;
  justify-content: center;

}

.envoie1 button,
.envoie2 button,
.envoie3 button, 
.envoie4 button {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px; 
  border-radius: 24px;
  transition: all 0.3s ease;
  border: none;
  background-color: transparent;

}
.envoie1 button p,
.envoie2 button p,
.envoie3 button p,
.envoie4 button p {
  color: #000000;
  transition: all 0.3s ease;
  border: none;
  background-color: transparent;
  font-size: 23px;
  font-weight: 800;


}

/* Style au survol */
.envoie1 button:hover,
.envoie2 button:hover,
.envoie3 button:hover,
.envoie4 button:hover {
  transform: translateY(-3px);
  box-shadow: 0 14px 8px rgba(0,0,0,0.3);
}

/* Style lorsque la page est active */ 
.bg-blue-100 {
  background: rgb(234, 153, 32); 
  color: #fff;
}

/* Style normal */
.text-black {
  color: #333;
}